// extracted by mini-css-extract-plugin
export var awardBadge = "styles-module--awardBadge--2738c";
export var detailNavigation = "styles-module--detailNavigation--26181";
export var factsWrapper = "styles-module--factsWrapper--aa6a6";
export var goldStatement = "styles-module--goldStatement--03c6e";
export var hl = "styles-module--hl--41ae5";
export var logoAndSocials = "styles-module--logoAndSocials--bbeb7";
export var manufactorLink = "styles-module--manufactorLink--466da";
export var mediaGallery = "styles-module--mediaGallery--579a0";
export var navigationText = "styles-module--navigationText--f1cf1";
export var noAwardBadge = "styles-module--noAwardBadge--7fd68";
export var participantHl = "styles-module--participantHl--82e94";
export var participantWrapper = "styles-module--participantWrapper--66828";
export var socialsContainer = "styles-module--socialsContainer--0d114";
export var socialsContainerSm = "styles-module--socialsContainerSm--3a212";
export var statement = "styles-module--statement--06656";
export var stickyContainer = "styles-module--stickyContainer--4ae96";
export var subline = "styles-module--subline--c9921";
export var tagsWrapper = "styles-module--tagsWrapper--d9647";
export var winnerImage = "styles-module--winnerImage--7f2ec";
export var withoutMargin = "styles-module--withoutMargin--f57db";
export var wrapper = "styles-module--wrapper--36a32";