import React from 'react';
import pt from 'prop-types';
import * as styles from './styles.module.scss';
import Text from '../../../../ui/text';

const GoldStatement = ({ className, headline, children }) => (
  <div className={className}>
    <Text as="h3" type="h3" variant="semibold" className={styles.hl}>
      {headline}
    </Text>
    <div className={styles.statementWrapper}>
      <Text as="span" type="p1" variant="editorial">
        {children}
      </Text>
    </div>
  </div>
);

GoldStatement.propTypes = {
  children: pt.node.isRequired,
  headline: pt.string.isRequired,
  className: pt.string,
};

GoldStatement.defaultProps = {
  className: '',
};

export default GoldStatement;
