import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLocale } from '../../../context/locale.context';
import { MicrocopyContext } from '../../../context/microcopy.context';
import { getCopy, getCopyWithFallback } from '../../../helpers/copy';
import { getAPIRoot } from '../../../helpers/fetch';
import { combine } from '../../../helpers/styles';
import useScroll from '../../../hooks/useScrollPosition';
import AwardBadge from '../../ui/awardBadge';
import DetailNavigation from '../../ui/detailPageNavigation';
import Fact from '../../ui/fact';
import Link from '../../ui/link';
import SocialShare from '../../ui/socialShare';
import Tag from '../../ui/tag';
import Text from '../../ui/text';
import GoldStatement from './components/goldStatement';
import MediaGallery from './components/mediaGallery';
import Participant from './components/participant';
import * as styles from './styles.module.scss';
import { AWARD_IDS } from '../../../utils/constants';

const ContributionDetail = ({ data }) => {
  const microcopy = useContext(MicrocopyContext);
  const mediaGalleryRef = useRef();
  const infoContainerRef = useRef();
  const { scrollDirection } = useScroll();
  const [scrollingDirection, setScrollingDirection] = useState('down');
  const [detailNavigation, setDetailNavigation] = useState(null);
  const { locale } = useLocale();
  const [prevFilterListType, setPrevFilterListType] = useState('');

  const style = { top: scrollingDirection === 'down' ? '94px' : '138px' }; // 138px --> 94px + extra 44px of the navi when scrolling up
  useEffect(() => {
    setScrollingDirection(scrollDirection);
  }, [scrollDirection]);

  const getDetailNavigation = async () => {
    const awardTypeID = data?.awardType;
    let type = '';
    if (awardTypeID == AWARD_IDS.IFSIP) {
      type = 'sip';
    } else if (awardTypeID == AWARD_IDS.IFDSA) {
      type = 'student';
    } else {
      type = 'project';
    }

    setPrevFilterListType(type);

    const payloadAsString = sessionStorage?.getItem(`${type}Payload`);
    const count = sessionStorage?.getItem(`${type}Count`) ?? 0;

    if (payloadAsString) {
      const payload = JSON.parse(payloadAsString);
      const apiRoot = await getAPIRoot();
      const res = await fetch(
        `${apiRoot}${process.env.GATSBY_API_ENTRIES_NAVIGATION}/${data.id}/${payload.page}/${payload.count}?order=${payload.sort}`,
        {
          method: 'POST',
          redirect: 'follow',
          headers: {
            'Content-Type': 'application/json',
          },
          body: payloadAsString,
        }
      );
      const json = await res.json();
      setDetailNavigation({
        count,
        ...json,
      });
    }
  };

  useEffect(() => {
    getDetailNavigation();

    return () => {
      sessionStorage?.removeItem('returnToSearchPage');
      sessionStorage?.removeItem('projectCount');
      sessionStorage?.removeItem('projectPayload');
      sessionStorage?.removeItem('projectQueryParams');
    };
  }, []);

  const mediaItems = [
    {
      url:
        (data.hasLargePrimaryMedia && data.primaryMedia) ||
        data.primaryMediaThumbnail,
      type: 1,
      description: data.name,
    },
    {
      url:
        (data.hasLargeSecondaryMedia && data.secondaryMedia) ||
        data.secondaryMediaThumbnail,
      type: 1,
      description: data.name,
    },
  ]
    .concat(
      data.media.map((item) => {
        return {
          url: item.content,
          type: item.type,
          description: data.name,
          loading: 'lazy',
        };
      })
    )
    .filter((image) => Boolean(image.url));

  const getOverviewLink = (searchTerm, key) => {
    const overviewURL = `/${locale}/winner-ranking/winner-overview`;

    const disciplineId = data.disciplineId;

    if (key === 'discipline' && disciplineId) {
      return `${overviewURL}?disciplineId=${disciplineId}`;
    }

    if (key === 'category') {
      const groupNameIndex = data.groupNames.findIndex(
        (name) => name === searchTerm
      );
      const categoryId = data.groupIds[groupNameIndex];

      return `${overviewURL}?categoryId=${categoryId}`;
    }

    return `${overviewURL}?find=${searchTerm}`;
  };

  const renderTags = () => {
    const discipline = {
      type: 'discipline',
      label: getCopyWithFallback(
        `filters.discipline.${data.disciplineId}`,
        microcopy,
        data.discipline
      ),
    };

    let categories = [];

    if (data.awardType === 5 || data.awardType === 1) {
      categories = [
        {
          type: 'category',
          label: data.category,
          // TODO: Use properly translated categories, when id mismatch on the backend is fixed
          // label: getCopyWithFallback(
          //   `filters.category.${data.categoryId}.${
          //     data.awardType === 1 ? 'talent' : 'sip'
          //   }`,
          //   microcopy,
          //   data.category
          // ),
        },
      ];
    } else {
      categories = data.groupNames.map((name, index) => ({
        type: 'category',
        label: getCopyWithFallback(
          `filters.category.${data.groupIds[index]}`,
          microcopy,
          name
        ),
        name: name,
      }));
    }

    const tags = [discipline, ...categories];
    return tags
      .filter((tag) => tag.label)
      .map((tag, index) => {
        if (data.awardType === 5 || data.awardType === 1) {
          return (
            <Tag key={index} interactive={false}>
              {tag.label}
            </Tag>
          );
        }
        return (
          <Link
            link={{
              linkToPage: getOverviewLink(tag.name || tag.label, tag.type),
            }}
          >
            <Tag htmlId={`entry-tag-${tag.type}`} key={index}>
              {tag.label}
            </Tag>
          </Link>
        );
      });
  };

  const chooseAwardBadge = () => {
    const { awardType, goldAward, status } = data;
    if (!awardType && !goldAward) return null;
    if (awardType === 1 && status === 37) {
      return 'ifTalentAward2021';
    }
    if (goldAward) {
      return 'ifDesignGold2021';
    } else if (awardType === 1) {
      return 'ifTalentAward2021';
    } else if (awardType === 2) {
      return 'ifDesignRed2021';
    } else if (awardType === 5) {
      return 'ifSocialImpactPrize2021';
    }
    return '';
  };

  const manufacturer = data.entryBusinessCards
    .filter((item) => item.type === 'client_manufacturer')
    .sort(
      (a, b) =>
        data.clients.indexOf(a.company) - data.clients.indexOf(b.company)
    );

  const talentRelatedEntry = data.entryBusinessCards.find(
    (item) => item.awardType === 'IF_DTA'
  );

  // For SIP awards, the designer has type submittingOrganization
  const designer =
    !talentRelatedEntry &&
    data.entryBusinessCards
      .filter(
        (item) =>
          item.type === 'designer' || item.type === 'submittingOrganization'
      )
      .sort(
        (a, b) =>
          data.designer.indexOf(a.company) - data.designer.indexOf(b.company)
      );

  const winners =
    talentRelatedEntry &&
    data.entryBusinessCards.map((entry) => ({
      name: entry.freeText,
      university: entry.company,
      image: entry.imageUrl,
    }));

  const professorData = data.professor?.split(',').map((str) => str.trim());

  const awardBadge = chooseAwardBadge();
  const uniqueBussiness = [
    ...new Map(
      data.entryBusinessCards
        .filter((el) => el.order === 1)
        .map((el) => [el['entryId'], el])
    ).values(),
  ];

  return (
    <>
      <DetailNavigation
        navigationObject={detailNavigation}
        type={prevFilterListType}
      />
      <div className={styles.wrapper}>
        {Boolean(mediaItems?.length) && (
          <div ref={mediaGalleryRef}>
            <MediaGallery
              media={mediaItems}
              className={styles.mediaGallery}
              isZoomable={true}
            />
          </div>
        )}
        <div>
          <div
            style={style}
            ref={infoContainerRef}
            className={styles.stickyContainer}
          >
            <div
              className={combine(
                styles.logoAndSocials,
                !awardBadge && styles.noAwardBadge
              )}
            >
              <AwardBadge
                name={awardBadge}
                year={data.awardYear}
                className={styles.awardBadge}
                trackingId
              />
              <SocialShare
                displayLabel
                isContributionDetail
                className={styles.socialsContainer}
                shareURL={`${process.env.GATSBY_DOMAIN}/${data.slug}`}
              />
            </div>
            <div className={styles.tagsWrapper}>{renderTags()}</div>
            <SocialShare
              className={styles.socialsContainerSm}
              shareURL={`${process.env.GATSBY_DOMAIN}/${data.slug}`}
            />

            {data.name && (
              <Text as="h1" type="h1" variant="semibold" className={styles.hl}>
                {data.name}
              </Text>
            )}
            {data.designation && (
              <Text as="h2" type="h1" className={styles.subline}>
                {data.designation}
              </Text>
            )}

            {manufacturer?.[0] &&
              (manufacturer[0].slug ? (
                <Link
                  link={{
                    linkToPage: manufacturer?.[0].slug,
                  }}
                  className={styles.manufactorLink}
                >
                  <Text as="span" type="metanavi">
                    {manufacturer[0].company}
                  </Text>
                </Link>
              ) : (
                <div className={styles.manufactorLink}>
                  <Text as="span" type="metanavi">
                    {manufacturer[0].company}
                  </Text>
                </div>
              ))}

            {data.description && (
              <Text as="p" type="p1">
                {data.description}
              </Text>
            )}

            {data.awardType !== 1 && data.goldAward && data.statement && (
              <GoldStatement
                className={styles.goldStatement}
                headline={getCopy('winner.goldStatement', microcopy)}
              >
                {data.statement}
              </GoldStatement>
            )}

            <div className={styles.participantWrapper}>
              {((!data.goldAward && data.statement) ||
                (data.awardType === 1 && data.goldAward)) && (
                <>
                  <Text
                    as="span"
                    type="infotext"
                    variant="semibold"
                    className={styles.participantHl}
                  >
                    {getCopy('winner.juryStatement', microcopy)}
                  </Text>
                  <Text as="p" className={styles.statement} variant="editorial">
                    {data.statement}
                  </Text>
                </>
              )}
              {data.winnerStatement && (
                <>
                  <Text
                    as="span"
                    type="infotext"
                    variant="semibold"
                    className={styles.participantHl}
                  >
                    {getCopy('winner.winnerStatement', microcopy)}
                  </Text>
                  <Text as="p" className={styles.statement} variant="editorial">
                    {data.winnerStatement}
                  </Text>
                </>
              )}
              {manufacturer?.length > 0 && (
                <>
                  <Text
                    as="span"
                    type="infotext"
                    variant="semibold"
                    className={styles.participantHl}
                  >
                    {getCopy('winner.clientManufacturer', microcopy)}
                  </Text>
                  {manufacturer.map((item, index) => (
                    <Participant
                      key={index}
                      logo={{
                        url: item.imageUrl,
                        description: item.company,
                      }}
                      name={item.company}
                      location={`${item.city}, ${item.countryIso}`}
                      link={{
                        linkToPage: item.slug,
                      }}
                      htmlId="manufacturer-brand"
                    />
                  ))}
                </>
              )}

              {designer?.length > 0 && (
                <>
                  <Text
                    as="span"
                    type="infotext"
                    variant="semibold"
                    className={styles.participantHl}
                  >
                    {getCopy('winner.designer', microcopy)}
                  </Text>
                  {designer.map((item, index) => (
                    <Participant
                      key={index}
                      logo={{
                        url: item.imageUrl,
                        description: item.company,
                      }}
                      name={item.company}
                      location={`${item.city}, ${item.countryIso}`}
                      link={{
                        linkToPage: item.slug,
                      }}
                      additionalInfo2={
                        data.awardType === AWARD_IDS.IFDA ? item.freeText : ''
                      }
                    />
                  ))}
                </>
              )}
              {talentRelatedEntry && (
                <>
                  {data?.entryBusinessCards?.length && (
                    <>
                      <Text
                        as="span"
                        type="infotext"
                        variant="semibold"
                        className={styles.participantHl}
                      >
                        {getCopy('winner.universities', microcopy)}
                      </Text>
                      {uniqueBussiness.map((entryBusinessCard) => (
                        <Participant
                          key={entryBusinessCard.company}
                          name={entryBusinessCard.company}
                          location={`${entryBusinessCard.city}, ${entryBusinessCard.countryIso}`}
                          link={{
                            linkToPage: null,
                          }}
                        />
                      ))}
                    </>
                  )}

                  {winners?.length > 0 && (
                    <>
                      <Text
                        as="span"
                        type="infotext"
                        variant="semibold"
                        className={styles.participantHl}
                      >
                        {getCopy('winner.winners', microcopy)}
                      </Text>
                      {winners.map((item, index) => (
                        <Participant
                          key={index}
                          name={item.name}
                          location={item.university}
                          logo={{ url: item.image }}
                          link={{
                            linkToPage: null,
                          }}
                          imgClassName={styles.winnerImage}
                        />
                      ))}
                    </>
                  )}
                  {professorData && (
                    <>
                      <Text
                        as="span"
                        type="infotext"
                        variant="semibold"
                        className={styles.participantHl}
                      >
                        {getCopy('winner.professor', microcopy)}
                      </Text>
                      <Participant
                        name={professorData[0]}
                        additionalInfo={professorData[1]}
                        link={{
                          linkToPage: null,
                        }}
                      />
                    </>
                  )}
                </>
              )}
            </div>

            <div className={styles.factsWrapper}>
              {data.dateOfLaunch && (
                <Fact
                  label={getCopy('fact.dateOfLaunch', microcopy)}
                  value={data.dateOfLaunch}
                  className={styles.withoutMargin}
                />
              )}
              {data.developmentTime && (
                <Fact
                  label={getCopy('fact.developmentTime', microcopy)}
                  value={data.developmentTime}
                  className={styles.withoutMargin}
                />
              )}
              {data.targetRegion && (
                <Fact
                  label={getCopy('fact.targetRegions', microcopy)}
                  value={data.targetRegion}
                  className={styles.withoutMargin}
                />
              )}
              {data.targetGroups && (
                <Fact
                  label={getCopy('fact.targetGroups', microcopy)}
                  value={data.targetGroups}
                  className={styles.withoutMargin}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContributionDetail;
