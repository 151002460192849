import React, { useEffect, useState } from 'react';
import pt from 'prop-types';
import TeaserItem from '../../../../ui/teaserItem';
import CustomScrollable from '../../../../ui/customScrollable';
import FirstItem from '../firstItem';
import { headlinePropTypes } from '../../../../ui/headline';
import { linkProptypes } from '../../../../ui/link';
import * as styles from './styles.module.scss';
import { getIfDesignAwardBadge } from '../../../../../helpers/badges';

import { getAPIRoot } from '../../../../../helpers/fetch';

const SpecificProjects = ({
  dataIds,
  layout,
  firstItemHeadline,
  firstItemText,
  firstItemLink,
}) => {
  const [projects, setProjects] = useState([]);

  const fetchProjects = async () => {
    const tmp = [];
    let apiRoot = await getAPIRoot();

    const resolve = dataIds.map(async (id, index) => {
      await fetch(`${apiRoot}${process.env.GATSBY_API_PROJECT_DETAIL}/${id}`)
        .then((res) => res.json())
        .then((res) => {
          const project = {
            image: { url: res.primaryMediaThumbnail, description: res.name },
            title: res.name,
            subline: res.designation,
            info: res?.clients?.[0],
            link: { linkToPage: res.slug },
            awardBadge: getIfDesignAwardBadge(res),
            position: index,
          };
          tmp.push(project);
        })
        .catch(() => console.error(`Error loading entry ${id}`));
    });
    await Promise.all(resolve);
    const sorted = tmp.sort((a, b) => a.position - b.position);
    setProjects(sorted);
  };

  useEffect(() => {
    fetchProjects();
  }, []);

  if (layout === 'grid') {
    return (
      <div className={styles.gridContainer}>
        {projects.length > 0
          ? projects.map((item, index) => <TeaserItem key={index} {...item} />)
          : Array.from(Array(6).keys()).map((_, index) => (
              <TeaserItem key={index} isSkeleton />
            ))}
      </div>
    );
  }

  return (
    <>
      <CustomScrollable className={styles.scrollContainer}>
        {firstItemHeadline && (
          <FirstItem
            headline={firstItemHeadline}
            text={firstItemText}
            link={firstItemLink}
          />
        )}
        <div className={styles.innerContainer}>
          {projects &&
            projects.map((item, index) => (
              <TeaserItem key={index} {...item} isInSlider />
            ))}
        </div>
      </CustomScrollable>
      <CustomScrollable className={styles.scrollContainerSm}>
        <div className={styles.innerContainerSm}>
          {projects &&
            projects.map((item, index) => (
              <TeaserItem key={index} {...item} isInSlider />
            ))}
        </div>
      </CustomScrollable>
    </>
  );
};

SpecificProjects.propTypes = {
  dataIds: pt.arrayOf(pt.string).isRequired,
  layout: pt.oneOf(['slider', 'grid']),
  firstItemHeadline: pt.shape(headlinePropTypes),
  firstItemText: pt.string,
  firstItemLink: pt.shape(linkProptypes),
};

SpecificProjects.defaultProps = {
  layout: 'slider',
  firstItemHeadline: null,
  firstItemText: '',
  firstItemLink: null,
};

export default SpecificProjects;
