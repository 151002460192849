import React from 'react';
import pt from 'prop-types';
import { combine } from '/src/helpers/styles';
import Text from '../text';
import * as styles from './styles.module.scss';

const Tag = ({ className, onClick, interactive, htmlId, children }) => (
  <button
    type="button"
    className={combine(
      styles.tag,
      interactive && styles.interactive,
      className
    )}
    onClick={onClick}
  >
    <Text as="span" type="tag" id={htmlId}>
      {children}
    </Text>
  </button>
);

Tag.propTypes = {
  onClick: pt.func,
  children: pt.node.isRequired,
  className: pt.string,
  interactive: pt.bool,
  htmlId: pt.string,
};

Tag.defaultProps = {
  className: '',
  interactive: true,
  onClick: () => null,
  htmlId: '',
};

export default Tag;
